dialog {
    position: absolute;
    left: 0; right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    border: solid;
    padding: 1em;
    background: white;
    color: black;
    display: block;
}

dialog:not([open]) {
    display: none;
}

// Use CSS custom properties to try and work around #109.
dialog + .backdrop,
._dialog_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    width: var(--width, 100%);
    height: 100%;
    height: var(--height, 100%);
}

dialog + .backdrop {
    background: rgba(0,0,0,0.1);
}

dialog.fixed {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
}
